import React from 'react'
import { Link } from 'gatsby'

export const ButtonLarge = ({ children, to, className, ...rest }) => {
  if (to) {
    return (
      <Link className={`btn  background-grey-3 btn-lg px-5 py-3 ${className}`} to={to} {...rest}>
        {children}
      </Link>
    )
  }
  return (
    <button
      className={`btn background-grey-3 btn-lg px-5 py-3 ${className}`}
      type="button"
      {...rest}>
      {children}
    </button>
  )
}

export const GradientButton = ({ children, to, className, ...rest }) => {
  if (to) {
    return (
      <Link
        className={`btn btn-light background-purple text-grey-1 ${className}`}
        to={to}
        {...rest}>
        {children}
      </Link>
    )
  }
  return (
    <button
      className={`btn btn-light background-purple text-grey-1 ${className}`}
      type="button"
      {...rest}>
      {children}
    </button>
  )
}

export const LightButton = ({ children, to, className, ...rest }) => {
  if (to) {
    return (
      <Link className={`btn background-grey-3 ${className}`} to={to} {...rest}>
        {children}
      </Link>
    )
  }
  return (
    <button className={`btn background-grey-3 ${className}`} type="button" {...rest}>
      {children}
    </button>
  )
}
