import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import ModalVideo from 'react-modal-video'
import objectFitImagesPolyfill from 'object-fit-images'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { ButtonLarge, GradientButton, LightButton } from '../components/button'
import { MultipleImageSlider } from '../components/productPage'

const Hero = ({ heroLine, video, still }) => (
  <section className="hero background-black position-relative">
    <video playsInline autoPlay muted loop className="d-none d-lg-block">
      <source src={`https:${video}`} type="video/mp4" />
    </video>
    <img src={`https:${still}`} className="hero-hidden-video d-block d-lg-none" alt="Exhause fan" />
    <div>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h1 className="font-weight-bold text-white text-center px-2">{heroLine}</h1>
        <ButtonLarge to="/contact-us" className="mt-4">
          <span className="text-uppercase font-weight-bold">Get in touch</span>
        </ButtonLarge>
      </div>
    </div>
  </section>
)

const Subtitles = ({ title }) => <h4 className="text-center mb-5 text-grey-9">{title}</h4>

const PartnerSection = ({ partnerTitle, partnerDescription, partnerImage }) => (
  <div className="col-lg-4 col-md-12">
    <div className="partner-img">
      <img
        src={`https:${partnerImage}`}
        className="polyfill-object-fit-contain-center"
        alt={partnerTitle}
      />
    </div>
    <div className="text-center">
      <h2 dangerouslySetInnerHTML={{ __html: partnerTitle }} className="mt-5"></h2>
    </div>
    <div className="mt-4">
      <h6 className="text-black">{partnerDescription}</h6>
    </div>
  </div>
)

const ImageAsBackgroundWithVideoSection = ({
  backgroundImage,
  video,
  isVideoOpen,
  toggleVideo,
}) => (
  <div className="container-fluid mbb-5">
    <div className="row mt-5 position-relative ptt-10 pbb-10">
      <img
        src={backgroundImage}
        alt={'alt'}
        className="img-as-bg filter-image-1-home object-position-top polyfill-object-fit-cover-top"
      />
      <div className="col d-flex justify-content-center">
        <ButtonLarge
          className="d-flex justify-content-center align-items-center"
          onClick={() => toggleVideo(true)}>
          <i className="fas fa-play-circle font-size-6 mr-2"></i>
          <div>Watch featured video</div>
        </ButtonLarge>
      </div>
      <ModalVideo
        channel="youtube"
        videoId={video}
        isOpen={isVideoOpen}
        controls="0"
        color="white"
        cc_load_policy="0"
        modestbranding="1"
        iv_load_policy="3"
        onClose={() => toggleVideo(false)}
      />
    </div>
  </div>
)

const FloatingCards = ({ title, description, floatingSize }) => (
  <div className="col-sm-3">
    <div className={`card border-0 shadow-lg rounded-lg floating-${floatingSize}`}>
      <div className="card-body">
        <h2 className="font-weight-bold text-center mb-0 text-purple">{title}</h2>
        <h5 className="text-center mb-0 mt-3">{description}</h5>
      </div>
    </div>
  </div>
)

const StatisticsSection = ({ statistics = [], title }) => (
  <div className="container mt-3 mb-5">
    <Subtitles title={title} />
    <div className="row mt-1">
      {statistics.map((stat) => (
        <FloatingCards
          key={stat.title}
          title={stat.title}
          description={stat.description}
          floatingSize={stat.floatingSize}
        />
      ))}
    </div>
  </div>
)

const GridSection = ({ items, title }) => (
  <section className="container mt-5">
    <Subtitles title={title} />
    <div className="row">
      {items.map((item) => (
        <div className="col-lg-4 py-3" key={item.projectName}>
          <div className="portfolio-card position-relative">
            <div className="portfolio-card-img">
              <img src={`https:${item.image.file.url}`} alt={''} className="responsive-img" />
            </div>
            <div className="portfolio-card-content px-2">
              <h5 className="portfolio-card-content-title text-white">{item.projectName}</h5>
              <p className="text-grey-2">{item.productApplication.productApplication}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
    <div className="row justify-content-center mt-5 pb-5">
      <GradientButton to="/projects" className="mr-5">
        View more of projects
      </GradientButton>
      <LightButton to="/clients">View clients we served</LightButton>
    </div>
  </section>
)

const Showcase = ({ products, title }) => (
  <div className="container-fluid mt-5">
    <div className="row">
      <div className="col-md-12">
        <Subtitles title={title} />
      </div>
    </div>
    <MultipleImageSlider products={products} />
  </div>
)

const EndNotesSection = ({ footerText }) => (
  <section className="container background-grey-6 mtt-7 pb-5 mbb-3 damma-round">
    <div className="row">
      <div className="col-12 col-lg-10 mx-auto text-center">
        <h6 className="mt-5 mb-3 text-white">{footerText}</h6>
        <GradientButton to="/contact-us">Lets get started</GradientButton>
      </div>
    </div>
  </section>
)

export default (props) => {
  const [isFanVideoOpen, toggleFanVideoOpening] = useState(false)

  useEffect(() => {
    objectFitImagesPolyfill()
  })

  const {
    pageTitle,
    subtitle,
    heroVideo: {
      file: { url: heroVideoUrl },
    },
    heroVideoStill: {
      file: { url: heroVideoStillUrl },
    },
    partners,
    featuredVideo,
    featuredVideoBg: {
      file: { url: featuredVideoBackgroundUrl },
    },
    productsTitle,
    projectsTitle,
    statisticsTitle,
    statistics,
    footerText,
  } = props.data.allContentfulPageHome.nodes[0]

  const { projects } = props.data.allContentfulPageProjects.nodes[0]
  const { products } = props.data.allContentfulPageProductsModal.nodes[0]

  const stats = statistics.map((stat) => ({
    title: stat.description.description,
    description: stat.title,
    floatingSize: stat.icon,
  }))

  return (
    <Layout pageURL={props.uri} shouldScrollExist={true}>
      <SEO title={pageTitle} />
      <Hero heroLine={subtitle} video={heroVideoUrl} still={heroVideoStillUrl} />
      <section className="container-fluid mt-5">
        {/* <Subtitles title={partnerTitle} /> */}
        <div className="row justify-content-center">
          {partners.map((partner) => (
            <PartnerSection
              partnerTitle={partner.partnerTitle}
              partnerDescription={partner.partnerDescription.partnerDescription}
              partnerImage={partner.partnerImage.file.url}
            />
          ))}
        </div>
      </section>
      <ImageAsBackgroundWithVideoSection
        backgroundImage={featuredVideoBackgroundUrl}
        video={featuredVideo}
        isVideoOpen={isFanVideoOpen}
        toggleVideo={toggleFanVideoOpening}
      />
      <Showcase products={products} title={productsTitle} />
      {/* <FeaturesSection title={featuresTitle} bullets={features} images={featuresImagesSrc} /> */}
      <GridSection items={projects.slice(0, 3)} title={projectsTitle} />
      <StatisticsSection title={statisticsTitle} statistics={stats} />
      <EndNotesSection footerText={footerText.footerText} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query HomeQueries {
    allContentfulPageHome {
      nodes {
        pageTitle
        subtitle
        heroVideo {
          file {
            url
          }
        }
        heroVideoStill {
          file {
            url
          }
        }
        partners {
          partnerTitle
          partnerImage {
            file {
              url
            }
          }
          partnerDescription {
            partnerDescription
          }
        }
        featuredVideo
        featuredVideoBg {
          file {
            url
          }
        }
        statistics {
          icon
          title
          description {
            description
          }
        }
        productsTitle
        projectsTitle
        statisticsTitle
        featuresTitle
        featuresImages {
          file {
            url
          }
        }
        features {
          title
          description {
            description
          }
        }
        footerText {
          footerText
        }
      }
    }
    allContentfulPageProductsModal {
      nodes {
        products {
          name
          imagesLq {
            file {
              url
            }
          }
          slug
        }
      }
    }
    allContentfulPageProjects {
      nodes {
        projects {
          projectName
          image {
            file {
              url
            }
          }
          productApplication {
            productApplication
          }
        }
      }
    }
  }
`
